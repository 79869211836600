<template>
  <div class="home">
    <div id="page-hero" class=" py-6 py-md-12 white--text py-md-12 container--fluid">
      <div class="container">
        <v-row>
          <v-col cols="12" sm="8">
            <!-- <h1>VeganFoodValues</h1> -->
            <h1 class="display-2 mb-4">Discover healthy vegan recipes</h1>
            <p>Powerful plant-based recipe search engine with a focus on nutritional content.</p>
            <p class="mb-0">
              <v-btn class="mr-4 mb-4" color="white" href="/recipes">Explore vegan recipes</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/approach">Our approach</v-btn>
            </p>
          </v-col>
          <v-col class="text-center" cols="12" sm="4">
            <img class="illustration" src="/illustrations/discover_healthy_vegan_recipes.svg" alt="Discover healthy vegan recipes"/>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-container>
      <v-row>
        <v-col>
          <h3 class="title">Recipes</h3>
          <RecipeGrid :recipes="data.recipes" v-if="data" />
          <v-btn class="mt-4" outlined href="/recipes">Explore all recipes &raquo;</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <div class="secondary white--text py-6 py-md-12">
      <div class="container">
        <v-row>
          <v-col cols="12" md="8">
            <h2 class="subtitle">Our mission:</h2>
            <h1 class="subtitle">"Encourage a vegan lifestyle by providing complete information on recipes, ingredients and nutrients"</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mr-4 mb-4" color="white" href="/about">About Us</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>
          <v-col class="text-center" cols="12" sm="4">
            <img class="illustration" src="/illustrations/encouraging_vegan_lifestyle.svg" alt="Encouraging a vegan lifestyle"/>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-container>
      <v-row>
        <v-col>
          <h3 class="title">Ingredients</h3>
          <IngredientGrid :items="data.foods" v-if="data" />
          <v-btn class="mt-4" outlined href="/ingredients">Explore all ingredients &raquo;</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <div class="green white--text py-6 py-md-12">
      <div class="container">
        <v-row>
          <v-col cols="12" md="8">
            <h2 class="subtitle">We are collecting <strong>vegan recipes</strong> from around the web so you can easily find them.</h2>
            <p class="mt-4">
              Our intelligent filters allow you to sort them by specific nutrient values, or filter out ingredients you might be allergic to.
            </p>
            <p class="mb-0">
              <v-btn class="mr-4 mb-4" color="white" href="/recipes">Explore vegan recipes</v-btn>
              <v-btn class="mr-4 mb-4" color="white" outlined href="/ingredients">Explore ingredients</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/nutrients">Explore nutrients</v-btn>
            </p>
          </v-col>
          <v-col class="text-center" cols="12" sm="4">
            <img class="illustration" src="/illustrations/collecting_vegan_recipes.svg" alt="Collecting and analyzing vegan recipes"/>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-container>
      <v-row>
        <v-col>
          <h3 class="title">Nutrients</h3>
          <NutrientGrid :items="data.nutrients" v-if="data" />
          <v-btn class="mt-4" outlined href="/nutrients">Explore all nutrients &raquo;</v-btn>
        </v-col>
      </v-row>
    </v-container>

  </div>
  
</template>

<script>
// @ is an alias to /src
import RecipeGrid from "@/components/RecipeGrid.vue";
import IngredientGrid from "@/components/IngredientGrid.vue";
import NutrientGrid from "@/components/NutrientGrid.vue";
import axios from "axios";

export default {
  name: "home",
  components: {
    RecipeGrid,
    NutrientGrid,
    IngredientGrid
  },
  data() {
    return {
      data: null,
    };
  },
  async mounted() {
    var response = await axios.get("/static/homepage.json");
    console.log('data', response, response.data);
    this.data = response.data;
  }, 
};
</script>

<style scoped>
</style> 